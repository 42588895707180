#project {
  background-color: #ffffff;
}

#project > main {
  margin-block-start: 1.5rem;
}

#project > main h1 {
  text-align: center;
  letter-spacing: 1.5px;
  color: #e20a13;
}

#project > main * {
  line-height: 1.6;
  word-spacing: 3px;
}

#project > main {
  border-bottom: 3px solid #e20a13;
  color: #333333;
  padding-inline: 15vw;
}

.project-img-container {
  height: 35vw;
}

.project-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.folder-icon {
  width: 40px;
  height: 30px;
  margin-inline-end: 10px;
}

.download-content {
  text-decoration: underline;
  display: flex;
  align-items: center;
  color: #e20a13;
}

.project-content {
  margin-block: 2rem;
}

.project-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-color: #333333;
  color: #ffffff;
  padding: 10px;
}

.project-info p {
  margin: 0;
}

.project-info:nth-child(even) {
  background-color: transparent;
  color: #333333;
}

.project-info a {
  color: #ffffff;
}

.project-btn {
  cursor: pointer;
  background-color: transparent;
  border: 2px solid #e20a13;
  color: #e20a13;
  padding: 0.2em 2.5em;
  border-radius: 1em;
  margin-block: 1rem;
}

.project-btn:hover {
  background-color: #d9d9d9;
  border: 2px solid #d9d9d9;
  color: black;
}

.project-btn:active {
  background-color: transparent;
  border: 2px solid #e20a13;
  color: black;
}

.project-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-block: 1rem;
}

.project-footer a {
  color: #333333;
}

.go-back > a {
  z-index: 3;
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  padding: 1rem 1rem;
  border-radius: 2rem;
  border: 1px solid #e20a1184;
  background-color: transparent;
  line-height: 1 !important;
  cursor: pointer;
  color: #e20a1184;
  text-decoration: none;
}

.go-back a:hover {
  color: #e20a13;
  border-color: #e20a13;
}

.go-back a:active {
  background-color: #e20a13;
  color: white;
}

@media screen and (max-width: 30em) {
  #project > main {
    padding: 1rem 10vw;
    margin-block-start: 1rem;
  }

  #project h1 {
    font-size: 25px;
  }

  #project p {
    line-height: 1.5;
    margin-block: 1rem;
  }

  #project h2 {
    margin-block: 0.5em;
  }

  #project h3 {
    font-size: 18px;
    margin-block: 0.4em;
  }

  .project-img-container {
    margin-inline-end: 0;
    height: 180px;
  }

  #project .download-content {
    font-size: 12px;
  }

  .tender-footer {
    font-size: 13px;
  }

  .go-back > a {
    bottom: 0.5rem;
    left: 0.5rem;
    font-size: 12px;
    padding: 0.8rem 0.8rem;
    border-radius: 1.4rem;
  }
}
