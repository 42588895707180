#tender {
  background-color: #ffffff;
}
#tender > main h1 {
  text-align: center;
  letter-spacing: 1.5px;
  color: #e20a13;
}

#tender > main * {
  line-height: 1.6;
  word-spacing: 3px;
}

#tender > main {
  border-bottom: 3px solid #e20a13;
  color: #333333;
  padding-inline: 15vw;
  margin-block-start: 1.5rem;
}

.tender-img-container {
  height: 35vw;
}

.tender-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#tender .folder-icon {
  width: 40px;
  height: 30px;
  margin-inline-end: 10px;
}

.download-content {
  text-decoration: underline;
  display: flex;
  align-items: center;
  color: #e20a13;
}

.tender-content {
  margin-block: 2rem;
}

.tender-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-color: #333333;
  color: #ffffff;
  padding: 10px;
}

.tender-info p {
  margin: 0;
}

.tender-info:nth-child(even) {
  background-color: transparent;
  color: #333333;
}

.tender-info a {
  color: #ffffff;
}

.tender-btn {
  cursor: pointer;
  background-color: transparent;
  border: 2px solid #e20a13;
  color: #e20a13;
  padding: 0.2em 2.5em;
  border-radius: 1em;
  margin-block: 1rem;
}

.tender-btn:hover {
  background-color: #d9d9d9;
  border: 2px solid #d9d9d9;
  color: black;
}

.tender-btn:active {
  background-color: transparent;
  border: 2px solid #e20a13;
  color: black;
}

.tender-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-block: 1rem;
}

.tender-footer a {
  color: #333333;
}

@media screen and (max-width: 30em) {
  #tender > main {
    padding: 1rem 1rem;
    margin-block-start: 1rem;
  }

  #tender h1 {
    font-size: 25px;
  }

  #tender p {
    line-height: 1.5;
    margin-block: 1rem;
  }

  #tender h2 {
    margin-block: 0.5em;
  }

  #tender h3 {
    font-size: 18px;
    margin-block: 0.4em;
  }

  .tender-img-container {
    margin-inline-end: 0;
    height: 180px;
  }

  #tender .tender-infos p {
    margin: 0;
    font-size: 12px;
  }

  #tender .tender-info .tender-info-label {
    margin-inline-end: 10px;
  }

  #tender .folder-icon {
    width: 30px;
    height: 20px;
    margin-inline-end: 5px;
  }

  #tender .download-content {
    font-size: 12px;
  }

  .tender-footer {
    font-size: 13px;
  }
}
