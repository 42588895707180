main#sign-up,
main#sign-in,
main#forgot {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../asset/images/auth.png);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

#sign-up > .form-container,
#sign-in > .form-container,
#forgot > .form-container {
  min-width: 70vw;
  min-height: 70vh;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  flex-flow: row wrap;
}

#sign-up .form-content,
#sign-in .form-content,
#forgot .form-content {
  position: relative;
  flex: 1 300px;
  min-height: 300px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.29), rgba(0, 0, 0, 0.29)),
    url(../asset/images/auth2.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  color: #fffeff;
  border-radius: 10px 0px 0px 0px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.go-home > a {
  z-index: 3;
  position: fixed;
  top: 1rem;
  left: 1rem;
  padding: 0.5rem 0.5rem;
  border-radius: 2rem;
  border: 1px solid #e20a1184;
  background-color: transparent;
  line-height: 1;
  cursor: pointer;
  color: #e20a1184;
  text-decoration: none;
}

.go-home a:hover {
  color: #e20a13;
  border-color: #e20a13;
}

.go-home a:active {
  background-color: #e20a13;
  color: white;
}

#sign-up .form-body,
#sign-in .form-body,
#forgot .form-body {
  flex: 1 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fffeff;
  border-radius: 0px 10px 0px 0px;
  padding: 2rem;
}

.form-content button {
  padding: 0.4rem 1.2rem;
  text-transform: uppercase;
  background-color: transparent;
  border: 1.5px solid #fffeff;
  color: #fffeff;
  cursor: pointer;
}

.form-content button:hover {
  background-color: #e20a13;
  color: #fffeff;
  border-color: #e20a13;
}

.form-content button:active {
  background-color: #d9d9d9;
}

#sign-up .form-control,
#sign-in .form-control,
#forgot .form-control {
  display: block;
  margin-block: 8px;
}

.form-control > label {
  display: block;
  color: rgba(51, 51, 51, 0.6);
  text-transform: uppercase;
}

#sign-up .form-control > input,
#sign-in .form-control > input,
#forgot .form-control > input {
  display: block;
  border: 1px solid;
  border-color: #d9d9d9;
  border-radius: 5px;
  margin-block-start: 10px;
  padding: 5px;
  box-sizing: border-box;
  width: 100%;
}

.form-control > button {
  background-color: #e20a13;
  color: white;
  border: none;
  margin-block-start: 10px;
  width: 100%;
  padding-block: 0.5rem;
  cursor: pointer;
}

.form-control > button:active {
  background-color: #33333376;
}

.loading-btn:disabled {
  background-color: #33333376;
  cursor: not-allowed;
}

.loading-btn:disabled:active {
  background-color: #33333376;
}

.input-error-msg {
  color: red;
  font-size: 14px;
}

.message,
.messag > * {
  text-align: center;
}

.message > button {
  background-color: #e20a13;
  color: white;
  border: 1px solid #e20a13;
  margin-block-start: 10px;
  width: 200px;
  padding-block: 0.5rem;
  cursor: pointer;
}

.message > button:hover {
  border-color: #e20a13;
  background-color: transparent;
  color: #e20a13;
}

/* mobile */
@media screen and (max-width: 30em) {
  #sign-up .form-content,
  #sign-in .form-content,
  #forgot .form-control {
    border-radius: 10px 10px 0px 0px;
    /* margin-block-start: 20rem; */
  }

  #sign-up .form-body,
  #sign-in .form-body #forgot .form-body {
    border-radius: 0px 0px 10px 10px;
    /* margin-block-end: 10rem; */
  }

  #sign-up > .form-container,
  #sign-in > .form-container,
  #forgot > .form-container {
    padding: 2rem;
  }

  .go-home > a {
    top: 0.3rem;
    left: 0.3rem;
    padding: 0.4rem 0.4rem;
  }
}

#forgot-text {
  color: #33333376;
  text-decoration: none;
  text-align: center;
  margin-block-start: 2rem;
}
