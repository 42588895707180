.content-container {
  padding: 3rem 10vw;
  display: flex;
  justify-content: center;
  /* flex-flow: row wrap; */
}

p {
  margin-block: 0px;
  line-height: 1.8;
  text-align: justify;
  hyphens: auto;
}

#mm p {
  text-align: left;
  hyphens: auto;
}

.content-container.welcome-container,
.content-container.about-us-container,
.content-container.project-container {
  background-color: #ffffff;
}

.content-container.news-container {
  justify-content: start;
  /* padding-inline: 0; */
  flex-direction: column;
}

.content-container.project-container {
  flex-direction: column;
}

.content-container.welcome-container {
  padding-block: 5rem;
  flex-flow: row wrap;
}

/* .content-container.contact-container {} */

.content-wrapper {
  flex: 1 400px;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
}

#home > .content-wrapper:nth-child(1) {
  flex: 2;
}

#home > .content-wrapper:nth-child(2) {
  display: block;
  flex: 3;
}

#home > .content-wrapper > div:nth-child(1) {
  display: flex;
}

#home > .content-wrapper > div:nth-child(1) > p {
  flex: 1;
  margin-right: 1.1rem;
}

#home > .content-wrapper > div:nth-child(1) > p:last-child {
  margin-right: 0;
}

#home ul {
  margin: 0;
  padding-left: 1rem;
}

#home li {
  list-style-position: outside;
  line-height: 1.8;
}

.content-wrapper > * {
  width: fit-content;
}

.content-wrapper h3 {
  margin: 0;
  font-size: 0.75rem;
  letter-spacing: 0.2em;
}

.content-wrapper h2 {
  margin-block: 1rem;
  font-size: 2rem;
  color: #e20a13;
}

.news-container > h2 {
  /* padding-inline: 10vw; */
  color: #e20a13;
}

/* .content-container.project-container .content-wrapper > h2 {
  color: white;
} */

.content-container.project-container .content-wrapper article h3 {
  text-align: center;
}

.content-wrapper:nth-child(1) {
  margin-inline-end: 2rem;
}

.welcome-container .content-wrapper:nth-child(2) {
  display: flex;
  flex-direction: row;
}

/* .welcome-container .content-wrapper:nth-child(2) > p {
  font-size: 15px;
  line-height: 2;
} */

.welcome-container .content-wrapper:nth-child(2) > p:nth-child(1) {
  margin-inline-end: 2rem;
}

.img-container > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.banner {
  height: 60vh;
  position: relative;
}

.banner > img,
.map-container > img,
.project-img-container > img {
  object-fit: cover;
}

.content-container.contact-container {
  background-color: #f7f7f7;
  flex-flow: row wrap;
}

.content-container.contact-container a {
  text-decoration: none;
  color: black;
}

.content-container.contact-container h5 {
  margin-block: 0.5rem;
  font-size: 1rem;
}

.content-container.contact-container .content-wrapper:nth-child(1) > div {
  margin-block: 0.5rem;
}

.content-container.contact-container .content-wrapper:nth-child(2) > form {
  width: 100%;
  height: 100%;
}

.contact-container h2 {
  font-size: 2rem;
}

.form-control {
  display: grid;
  grid-template-columns: 2fr 5fr;
  gap: 10px;
  margin-block: 1rem;
  align-items: center;
}

.home-form-control-group {
  display: flex;
  flex-direction: column;
}

.phone-form-control-group {
  display: grid;
  grid-template-columns: 1fr 6fr;
  gap: 5px;
}

.phone-form-control-group > input:first-child {
  width: 80px;
}

.contact-container .form-control > label {
  color: #333333;
}

.form-control:last-child {
  grid-template-columns: 1fr;
}

.btn {
  /* display: block; */
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.btn:hover {
  background-color: #979896;
}

.contact-container .form-control > button {
  width: 100px;
}

.form-btn {
  justify-self: end;
  border: 1px solid white;
  background-color: white;
}

#home input,
select {
  /* border: 1px solid white; */
  padding: 0.2rem 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.5);
  background-color: white;
}

.content-container.services-container {
  padding-inline: 15vw;
  flex-direction: column;
  text-align: center;
  /* background-image: url("../asset/images/services.png");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover; */
  color: white;
  position: relative;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: url("../asset/images/service_bg.png");
}

.service-card,
.service-title {
  backdrop-filter: blur(2px) saturate(100%);
  -webkit-backdrop-filter: blur(2px) saturate(100%);
  background-color: rgba(108, 108, 108, 0.3);
  border-radius: 12px;
  border: 1px solid rgba(108, 108, 108, 0.3);
}

.service-title span {
  color: #e20a13;
}

.service-title h2 {
  font-size: 2rem;
}

.service-body {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  margin-block-start: 1rem;
}

.service-body > a {
  text-decoration: none;
  color: unset;
}

.service-card {
  min-height: 180px;
  transition: all 0.5s ease-in-out;
  transform-origin: bottom;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  position: relative;
}

.service-card > h3,
.service-card > p {
  z-index: 2;
}

.service-card > img.service-img {
  z-index: 1;
  transition: 0.3s all ease-in-out;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 12px;
  /* visibility: hidden; */
  transform: scale(0);
  filter: contrast(75%);
}

/* .service-card:hover  {
  background-color: white;
  color: black;
} */

.service-card:hover > img.service-img {
  visibility: visible;
  transform: scale(1);
}

/* .service-lg-img-container {
  height: 280px;
} */

#client .img-container,
#news .img-container {
  height: 260px;
}

main .img-container > img {
  object-fit: cover;
}

.project-first-content {
  height: 100px;
  margin-block: 1rem;
}

.slide-container {
  flex: 1;
  height: fit-content;
}

.content-wrapper.project-content {
  flex: 1;
  display: flex;
  flex-direction: row;
  margin-block-end: 1rem;
}

.content-wrapper.project-content > div {
  flex: 1 400px;
}

.content-wrapper.project-content > p {
  flex: 1 400px;
  height: auto;
}

#location {
  height: auto;
}

#location > img {
  object-fit: contain;
}

.slide-content > p {
  box-sizing: border-box;
  width: 100%;
  height: 180px;
  padding-inline-end: 5px;
  overflow: hidden;
  overflow-y: hidden;
  overflow-y: scroll;
  word-break: break-word;
}

#home .project-content {
  margin-block: 1rem;
}

.values-container {
  border: 2px solid #e20a13;
  border-radius: 15px;
  padding: 0 1.5rem 2rem 2rem;
  margin-block-start: 2rem;
}

.values-container > h4 {
  background-color: #e20a13;
  margin: 0;
  padding: 0.5rem 1.5rem;
  width: fit-content;
  text-transform: uppercase;
  color: white;
  margin-block-end: 1.5rem;
  margin-inline: auto;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.ProjectsSwiper p {
  margin-block-end: 15px;
}

@media screen and (max-width: 30em) {
  .banner {
    height: 270px;
  }

  .content-container,
  .content-container {
    padding-block: 2rem;
  }

  .content-wrapper:nth-child(1) {
    margin-inline-end: 0;
    /* align-items: center; */
  }

  .welcome-container > .content-wrapper:nth-child(1),
  .about-us-container > .content-wrapper:nth-child(1),
  .news-container > h2,
  .contact-container h2 {
    /* text-align: center; */
    align-items: center;
    align-self: center;
  }

  .welcome-container .content-wrapper:nth-child(2) > p {
    line-height: 1.5;
  }

  .welcome-container .content-wrapper:nth-child(2) > p:nth-child(1) {
    margin-inline-end: 10px;
  }

  .content-container.about-us-container {
    flex-flow: wrap-reverse;
  }

  .content-container.about-us-container > .content-wrapper:nth-child(1) {
    margin-block-start: 2rem;
  }

  .content-container.services-container {
    padding-inline: 1rem;
  }

  p {
    line-height: 1.5;
    font-size: 13px;
  }

  li {
    line-height: 1.5;
    font-size: 13px;
  }

  h2 {
    margin-block: 0.5em;
  }

  h3 {
    font-size: 18px;
    margin-block: 0.4em;
  }

  .service-card p {
    font-size: 13px;
  }

  .service-body {
    grid-template-columns: 1fr;
  }

  .service-card {
    min-height: 100px;
  }

  .content-wrapper.project-content {
    flex-flow: row wrap;
  }

  .content-wrapper:nth-child(1) > .project-title {
    text-align: center;
  }

  .form-control {
    grid-template-columns: 1fr;
  }

  .contact-container .content-wrapper:nth-child(2) {
    margin-block-start: 1rem;
  }

  #home > .content-wrapper > div:nth-child(1) {
    flex-direction: column;
  }

  .content-container.welcome-container {
    /* padding-inline: 1rem; */
    flex-direction: column;
  }
}
