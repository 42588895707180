#tenders {
  background-color: #ffffff;
}

#tenders > main * {
  line-height: 1.6;
  word-spacing: 3px;
}

#tenders > main h1 {
  letter-spacing: 1.5px;
  text-align: center;
  color: #e20a13;
}

#tenders h3 {
  letter-spacing: 1.5px;
  margin-block: 0.8rem;
  color: #e20a13;
}

#tenders > main {
  border-bottom: 3px solid #e20a13;
  color: #333333;
  padding: 1rem 15vw;
}

.tenders-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-block: 2rem;
  flex-flow: row wrap;
}

.tenders-img-container,
.tenders-body {
  flex: 1 300px;
}

.tenders-img-container {
  margin-inline-end: 2rem;
  height: 250px;
}

.tenders-img-container > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tender-detail-btn {
  cursor: pointer;
  background-color: transparent;
  border: 2px solid #e20a13;
  color: #e20a13;
  margin-block: 1rem;
  padding: 0.2em 2.5em;
  border-radius: 1em;
}

.tender-detail-btn:hover {
  background-color: #333333;
  border: 2px solid #333333;
  color: #ffffff;
}

.tender-detail-btn:active {
  background-color: transparent;
  border: 2px solid #333333;
  color: black;
}

.tenders-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tenders-header > span {
  margin-inline-start: 1rem;
  font-size: 11px;
  font-style: italic;
  font-stretch: 85%;
}

@media screen and (max-width: 30em) {
  #tenders > main {
    padding: 1rem 2rem;
  }

  #tenders h1 {
    font-size: 25px;
  }

  #tenders p {
    line-height: 1.5;
    margin-block: 1rem;
  }

  #tenders h2 {
    margin-block: 0.5em;
  }

  #tenders h3 {
    font-size: 18px;
    margin-block: 0.4em;
  }

  .tenders-img-container {
    margin-inline-end: 0;
    height: 150px;
  }

  .tender-detail-btn {
    padding: 0.1em 1.5em;
  }
}
