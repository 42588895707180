header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding-inline: 1rem;
  z-index: 2;
  background-color: rgba(108, 108, 108, 0.3);
}

header > a {
  text-decoration: none;
}

header h1 {
  color: black;
  font-size: 1.8rem;
  letter-spacing: 0.02em;
  font-weight: 500;
}

button.menu-btn-container {
  border: none;
  background-color: transparent;
  cursor: pointer;
  z-index: 2;
}

.menu-container {
  position: absolute;
  top: 0rem;
  right: 0rem;
  display: flex;
  transform-origin: top left;
  z-index: 10;
}

.menu-content-container {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.382), rgba(0, 0, 0, 0.574)),
    rgba(217, 217, 217, 0.5);
  box-sizing: border-box;
  height: 60vh;
  width: 240px;
  padding: 2rem;
  padding-block-start: 0;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;
}

.menu-content-container > ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  list-style-position: inside;
}

.menu-content-container > ul > li {
  margin-block: 1.1rem;
}

.menu-content-container > ul > li:first-child {
  margin-block: 0;
}

button.close {
  background-color: transparent;
  color: white;
  border: 0;
  margin-block-start: 1rem;
  text-align: right;
  cursor: pointer;
  padding: 0.5rem;
  width: fit-content;
  align-self: flex-end;
}

/* .menu-content-container > ul > li > button {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.menu-content-container > ul > li > button:hover {
  text-decoration: underline;
} */

.open-menu {
  width: auto;
  visibility: visible;
}

.close-menu {
  width: 0;
  visibility: hidden;
}

.show-btn {
  scale: 1;
  visibility: visible;
}

.hide-btn {
  scale: 0;
  visibility: hidden;
}

/* #menu {
    transition: all 1s ease-out;
  } */

/* .menu-fill {
  width: calc(100vw - 240px);
  height: 60vh;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
    rgba(217, 217, 217, 0.5);
  cursor: pointer;
} */

.menu-enter {
  /* opacity: 0; */
  transform: translateX(100vw);
}

.menu-enter-active {
  /* opacity: 1; */
  transform: translateX(0vw);
  transition: all 1000ms;
}

.menu-exit {
  /* opacity: 1; */
  transform: translateX(0vw);
}

.menu-exit-active {
  /* opacity: 0; */
  transform: translateX(100vw);
  transition: all 1000ms;
}

.vesta {
  /* color: #e20a13; */
  color: red;
}

header li a,
.lang-select > a {
  text-decoration: none;
  color: white;
}

header li a:hover,
.lang-select > a:hover {
  text-decoration: underline;
}

.header-footer {
  display: flex;
  justify-content: space-between;
  margin-block-end: 7px;
}

.lang-select {
  color: white;
  display: flex;
  justify-content: flex-end;
}

.lang-select > button,
.header-footer > button {
  background-color: transparent;
  border: 0;
  color: white;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.lang-select > button:hover,
.header-footer > button:hover {
  text-decoration: underline;
}

.lang-select > button:nth-child(1) {
  margin-inline-end: 10px;
}

.lang-select > button:nth-child(2) {
  border-left: 1px solid white;
  padding-inline-start: 10px;
}

button#float-btn {
  z-index: 3;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  padding: 1rem 1rem;
  border-radius: 2rem;
  border: 1px solid #e20a1184;
  color: #e20a1184;
  background-color: transparent;
  line-height: 1;
  cursor: pointer;
}

#float-btn:hover {
  border: 1px solid #e20a13;
  color: #e20a13;
}

#float-btn:active {
  background-color: #e20a13;
  color: white;
}

.logo {
  margin: 5px;
  width: 6rem;
}

/* mobile */
@media screen and (max-width: 30em) {
  .menu-content-container {
    min-width: 50vw;
    min-height: 40vh;
    height: auto;
    width: auto;
    padding: 1rem;
    padding-block-start: 0;
  }

  button.close {
    margin-block-start: 10px;
  }

  .menu-fill {
    height: 270px;
    width: calc(100vw - 200px);
  }

  .menu-content-container > ul > li {
    margin-block: 6px;
  }

  .menu-content-container > ul > li > button {
    font-size: 16px;
  }

  button#float-btn {
    bottom: 0.5rem;
    right: 0.5rem;
    font-size: 12px;
    padding: 0.8rem 0.8rem;
    border-radius: 1.4rem;
  }

  .header-footer {
    line-height: 1.5;
    font-size: 13px;
  }

  .logo {
    width: 5rem;
  }
}
