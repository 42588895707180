#submit {
  background-color: #979896;
}

#submit h1 {
  letter-spacing: 1.5px;
}

#submit * {
  line-height: 1.6;
  word-spacing: 3px;
}

#submit > main {
  background-color: #ededed;
  margin: 1rem 15vw;
  padding: 2rem;
}

#submit .form-group-upload {
  margin-block-start: 1rem;
}

#submit .form-group-upload p {
  text-align: center;
}

#submit .form-control-group {
  display: flex;
  border-top: 5px solid #d9d9d9;
  padding-block: 1rem;
  flex-flow: column wrap;
}

#submit .option-content {
  margin-block: 1.5rem;
}

#submit .option-content > p {
  text-align: center;
}

/* .form-control-group > h3 {
  flex: 1 300px;
}

.form-control-group > div {
  flex: 2;
} */

#submit .form-control {
  display: block;
}

#submit .form-control > * {
  width: 100%;
}

#submit .form-control > input,
#submit .form-control > textarea {
  background-color: rgba(231, 231, 231, 1);
  border: 1px solid rgba(0, 0, 0, 0.11);
  padding: 5px 10px;
  box-sizing: border-box;
  line-height: 1.5;
  resize: vertical;
}

#submit .form-control > input {
  width: 100%;
  height: 200px;
  background-color: transparent;
  border: 1px dashed black;
  text-align: center;
}

.form-control-group.btn-group {
  justify-content: end;
}

.submit-btn {
  cursor: pointer;
  background-color: #333333;
  border: 2px solid #333333;
  color: white;
  padding: 0.2em 2.5em;
  border-radius: 1em;
  margin-block: 1rem;
}

.cancel-btn {
  cursor: pointer;
  background-color: transparent;
  border: 2px solid #d9d9d9;
  color: rgba(0, 0, 0, 0.5);
  padding: 0.2em 2.5em;
  border-radius: 1em;
  margin-block: 1rem;
  margin-inline-end: 1rem;
}

.drop_zone > p {
  margin: 7px;
}

@media screen and (max-width: 30em) {
  #submit > main {
    background-color: #ededed;
    margin: 1rem 1rem;
    padding: 1rem;
  }

  #submit h1 {
    margin-block: 1rem;
  }

  .submit-btn,
  .cancel-btn {
    padding: 0.1em 1.5em;
    border-radius: 1em;
    margin-block: 0.2rem;
    font-size: 13px;
  }

  .cancel-btn {
    margin-inline-end: 0;
  }

  .form-control-group.btn-group {
    justify-content: space-around;
  }

  #submit .form-control > input::placeholder {
    font-size: 13px;
  }
}

.submit-btn:hover,
.cancel-btn:hover {
  background-color: #d9d9d9;
  border: 2px solid #d9d9d9;
  color: black;
}

.submit-btn:active,
.cancel-btn:active {
  background-color: transparent;
  border: 2px solid #d9d9d9;
  color: black;
}

.input-error {
  border: 1px solid red !important;
}

.loading-btn:disabled {
  background-color: #33333376;
  cursor: not-allowed;
}

.loading-btn:disabled:active {
  background-color: #33333376;
}

.input-error-msg {
  color: red;
  font-size: 14px;
}

#file,
#img,
#ndaFile {
  display: none;
}

.drop_zone {
  border: 1px dashed black;
  border-radius: 5px;
  width: 100%;
  height: 200px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.drop-active {
  border: 1px solid var(--priamry-color);
}

.placeholder {
  font-size: 12px;
  color: gray;
}
