.alert-container {
  position: fixed;
  bottom: 1em;
  right: 0px;
  border: 1px solid green;
  padding-inline-start: 1em;
  padding-inline-end: 1em;
  border-right-width: 0px;
  border-radius: 10px 0px 0px 10px;
  background-color: white;
}

.alert-container > p {
  color: green;
}

.alert-container.error-alert {
  border-color: red;
}

.alert-container.error-alert > p {
  color: red;
}
