#service {
  background-color: #ffffff;
}

#service > main {
  margin-block-start: 1.5rem;
  position: relative;
}

#service > main h1 {
  text-align: center;
  letter-spacing: 1.5px;
  color: #e20a13;
}

#service > main * {
  line-height: 1.6;
  word-spacing: 3px;
}

#service > main {
  border-bottom: 3px solid #e20a13;
  color: #333333;
  padding-inline: 15vw;
}

.service-img-container {
  height: 35vw;
}

.service-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.service-content {
  margin-block: 2rem;
}

.service-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-block: 1rem;
}

.service-footer a {
  color: #333333;
}

.service-info-content {
  color: #998080;
}

.service-info-content > span {
  display: inline-block;
  margin-inline-start: 1rem;
}

.go-back > a {
  z-index: 3;
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  padding: 1rem 1rem;
  border-radius: 2rem;
  border: 1px solid #e20a1184;
  background-color: transparent;
  line-height: 1 !important;
  cursor: pointer;
  color: #e20a1184;
  text-decoration: none;
}

.go-back a:hover {
  color: #e20a13;
  border-color: #e20a13;
}

.go-back a:active {
  background-color: #e20a13;
  color: white;
}

@media screen and (max-width: 30em) {
  #service > main {
    padding: 1rem 10vw;
    margin-block-start: 1rem;
  }

  #service h1 {
    font-size: 25px;
  }

  #service p {
    line-height: 1.5;
    margin-block: 1rem;
  }

  #service h2 {
    margin-block: 0.5em;
  }

  #service h3 {
    font-size: 18px;
    margin-block: 0.4em;
  }

  .service-img-container {
    margin-inline-end: 0;
    height: 180px;
  }

  .go-back > a {
    bottom: 0.5rem;
    left: 0.5rem;
    font-size: 12px;
    padding: 0.8rem 0.8rem;
    border-radius: 1.4rem;
  }
}
