footer {
  background-color: #ffffff;
}

footer > div.footer-content {
  padding: 3rem 10vw;
  font-size: 14px;
}

.footer-content h5 {
  font-weight: bold;
}

footer > div.footer-content > div {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

footer > div.footer-end {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

footer > div.footer-end > p,
footer > div.footer-end > button {
  margin-inline: 1rem;
}

footer > div.footer-end > button {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  box-sizing: border-box;
  padding: 0;
}

footer > div.footer-end > button:hover {
  color: gray;
}

footer > div.footer-end > button:active {
  color: black;
}

footer h3 {
  font-weight: normal;
  color: #e20a13;
}

footer h5 {
  font-weight: normal;
  margin-block: 0.5rem;
  font-size: 14px;
}

footer section {
  flex: 1;
}

footer div.footer-logo {
  flex: 1;
  text-align: center;
  display: inline-flex;
  justify-content: center;
}

.footer-logo > .img-container {
  width: 220px;
  height: 220px;
}

.footer-content-container {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}

.footer-content-container > div:first-child {
  flex: 2;
  margin-inline-end: 1rem;
}

.footer-content-container > div {
  flex: 1 100px;
  margin-inline-end: 1rem;
}

.footer-content-container > div:last-child {
  margin: 0;
}

.footer-content-container a {
  color: black;
  text-decoration: none;
}

.footer-content-container a:hover {
  text-decoration: underline;
}

.footer-content-container ul {
  padding: 0;
  list-style-type: none;
  list-style-position: inside;
}

.footer-content-container li {
  margin-block-end: 0.5rem;
}

.vesta {
  color: #e20a13;
}

address {
  font-style: normal;
}

.modal {
  position: fixed;
  left: calc(50% - 230px);
  top: calc(50% - 130px);
  background-color: white;
  padding: 1rem 2rem;
  z-index: 2;
  box-shadow: inset 0 -0.5em 2em rgba(0, 0, 0, 0.1),
    0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
  transition: all 0.5s ease-in-out;
  box-sizing: border-box;
  width: 460px;
  height: 360px;
  transform-origin: center center;
  scale: 1;
}

.modal-close {
  opacity: 0;
  transform: scale(0);
}

.modal-open {
  opacity: 1;
  transform: scale(1);
}

.modal > button {
  margin: 10px 0px;
  position: absolute;
  right: 2rem;
  padding: 5px 10px;
  border: 1px solid #e20a1184;
  color: #e20a1184;
  background-color: transparent;
  line-height: 1;
  cursor: pointer;
}

.modal > button:hover {
  border: 1px solid #e20a13;
  color: #e20a13;
}

.modal > button:active {
  background-color: #e20a13;
  color: white;
}

.mark {
  position: absolute;
  top: 0;
  right: 5px;
}

@media screen and (max-width: 30em) {
  /* .footer-content-container {
    flex-flow: row wrap;
    flex: 3;
  } */

  .footer-content-container > div {
    flex: 1 250px;
  }

  footer > div.footer-content {
    padding: 2rem 1rem;
  }

  footer > div.footer-content > div > div {
    flex: 2;
  }

  footer > div.footer-end {
    font-size: 14px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  footer > div.footer-end > p:nth-child(1) {
    grid-column: 1/3;
    grid-row: 1;
  }

  footer > div.footer-end > p {
    text-align: center;
  }

  .footer-logo > .img-container {
    width: 120px;
    height: 120px;
  }

  .modal {
    left: calc(50% - 150px);
    top: calc(50% - 150px);
    padding: 0.5rem 1rem;
    width: 300px;
    height: 260px;
  }

  .modal > button {
    font-size: 12px;
    margin: 10px 0px;
    right: 1rem;
    padding: 5px 10px;
  }

  .mark {
    position: unset;
    font-size: 13px;
  }
}
