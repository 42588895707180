#success > main {
  background-image: url("../asset/images/successBg.png");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#success h1 {
  margin-block: 2rem;
  color: #e20a13;
}

#success p {
  margin: 0;
  line-height: 1.5;
}

#success .card {
  width: 450px;
  /* height: 400px; */
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#success button {
  margin-block: 2rem;
  background-color: #e20a13;
  color: white;
  border: 1px solid #e20a13;
  width: 200px;
  padding-block: 0.5rem;
  cursor: pointer;
  border-radius: 10px;
}

#success button:hover {
  border-color: #e20a13;
  background-color: transparent;
  color: #e20a13;
}
