.swiper {
  height: 100%;
}

/* .swiper-wrapper {
  width: 100%;
  height: 100px;
} */

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0;
  right: 10px;
}

.swiper-pagination {
  text-align: center !important;
}

.ProjectsSwiper a {
  text-decoration: none;
  color: unset;
}

.swiper-pagination-bullet-active {
  background-color: #e20a13 !important;
}

.NewsSwiper h2 {
  color: #e20a13;
  text-transform: uppercase;
  font-family: "myFont1";
}

.ProjectsSwiper h2 {
  text-align: center;
  text-transform: uppercase;
  font-family: "myFont1";
}

/* mobile */
@media screen and (max-width: 30em) {
  .BannerSwiper {
    height: 270px;
  }
}
